import React from 'react';

import {frontend, useLocale} from 'pageflow-scrolled/frontend';

import styles from './footer.module.css';

import logo from '../images/logoStacked.svg';

import {translations} from '../translations';

frontend.widgetTypes.register('websiteFooter', {
  component: function Footer() {
    const locale = useLocale();
    const t = translations[locale === 'de' ? 'de' : 'en'];

    return (
      <footer className={styles.footer}>
        <a className={styles.logo} href={t.start.url}>
          <img src={logo} alt="Pageflow Logo" />
        </a>
        <div className={styles.columns}>
          <div className={styles.column}>
            <h2>{t.product}</h2>
            <ul>
              <LinkItem {...t.start} />
              <LinkItem {...t.pricing} />
              <LinkItem {...t.register}
                        analyticsEvent="Register: Click"
                        analyticsId="Footer" />
              <LinkItem {...t.login}
                        analyticsEvent="Login: Click"
                        analyticsId="Footer" />
            </ul>
          </div>
          <div className={styles.column}>
            <h2>{t.resources}</h2>
            <ul>
              <LinkItem {...t.blog} />
              <LinkItem {...t.helpdesk} />
              <LinkItem {...t.changelog} />
            </ul>
          </div>
          <div className={styles.column}>
            <h2>{t.about}</h2>
            <ul>
              <LinkItem {...t.legal} />
              <LinkItem {...t.terms} />
              <LinkItem {...t.privacy} />
            </ul>
          </div>
          <div className={styles.column}>
            <h2>{t.followUs}</h2>
            <ul>
              <LinkItem label="Bluesky" url="https://bsky.app/profile/pageflow.io" />
              <LinkItem label="GitHub" url="https://github.com/codevise/pageflow" />
              <LinkItem label="LinkedIn" url="https://www.linkedin.com/company/pageflow-app/" />
              <LinkItem label="X" url="https://x.com/pageflow_io" />
            </ul>
          </div>
        </div>
        <div className={styles.copy}>
          <a href="https://codevise.de" target="_blank" rel="noopener noreferrer">
            © {new Date().getFullYear()} Codevise Solutions
          </a>
        </div>
      </footer>
    );
  }
});

function LinkItem({label, url, analyticsEvent, analyticsId}) {
  return (
    <li>
      <a href={url}
         data-event={analyticsEvent}
         data-event-props={analyticsId && JSON.stringify({id: analyticsId})}>
        {label}
      </a>
    </li>
  );
}
